// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

b {
    margin: 0 0.3em;
}

/**
 * Aspect ratio
 * Example: <div style="--custom-aspect-ratio=16/9">...</div>
 */
[style*="--custom-aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--custom-aspect-ratio"] > img {
    height: auto;
}
@supports (--custom: property) {
    [style*="--custom-aspect-ratio"] {
        position: relative;
    }
    [style*="--custom-aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / (var(--custom-aspect-ratio)));
    }
    [style*="--custom-aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/theme/theme.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;;;EAGE;AACF;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,WAAW;QACX,cAAc;QACd,yDAAyD;IAC7D;IACA;QACI,kBAAkB;QAClB,MAAM;QACN,OAAO;QACP,YAAY;IAChB;AACJ","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\nb {\n    margin: 0 0.3em;\n}\n\n/**\n * Aspect ratio\n * Example: <div style=\"--custom-aspect-ratio=16/9\">...</div>\n */\n[style*=\"--custom-aspect-ratio\"] > :first-child {\n    width: 100%;\n}\n[style*=\"--custom-aspect-ratio\"] > img {\n    height: auto;\n}\n@supports (--custom: property) {\n    [style*=\"--custom-aspect-ratio\"] {\n        position: relative;\n    }\n    [style*=\"--custom-aspect-ratio\"]::before {\n        content: \"\";\n        display: block;\n        padding-bottom: calc(100% / (var(--custom-aspect-ratio)));\n    }\n    [style*=\"--custom-aspect-ratio\"] > :first-child {\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
