// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fr.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./en.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_LangSelector_Ge6ft_ {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.5em;
}

.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_fr_QHbKzX {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_en_r0I8xg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_LangSelector_Ge6ft_ > button {
    border: none;
    background-color: transparent;
    width: 3em;
    height: 3em;
    cursor: pointer;
    transition: 0.3 all;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(0.8);
    border-radius: 50%;
    box-shadow: 0 4px 8px #0009;
}

.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_LangSelector_Ge6ft_ > button.\\------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_selected_lnOAFr {
    transform: scale(1);
    box-shadow: 0 0 2px 2px var(--theme-color-primary-7);
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/LangSelector/LangSelector.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,yDAA+B;AACnC;;AAEA;IACI,yDAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,UAAU;IACV,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,wBAAwB;IACxB,4BAA4B;IAC5B,qBAAqB;IACrB,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,oDAAoD;AACxD","sourcesContent":[".LangSelector {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    gap: 0.5em;\n}\n\n.fr {\n    background-image: url(./fr.png);\n}\n\n.en {\n    background-image: url(./en.png);\n}\n\n.LangSelector > button {\n    border: none;\n    background-color: transparent;\n    width: 3em;\n    height: 3em;\n    cursor: pointer;\n    transition: 0.3 all;\n    background-size: contain;\n    background-repeat: no-repeat;\n    transform: scale(0.8);\n    border-radius: 50%;\n    box-shadow: 0 4px 8px #0009;\n}\n\n.LangSelector > button.selected {\n    transform: scale(1);\n    box-shadow: 0 0 2px 2px var(--theme-color-primary-7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LangSelector": `------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_LangSelector_Ge6ft_`,
	"fr": `------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_fr_QHbKzX`,
	"en": `------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_en_r0I8xg`,
	"selected": `------lib-tp-core-dist-ui-view-LangSelector-LangSelector-module_selected_lnOAFr`
};
export default ___CSS_LOADER_EXPORT___;
