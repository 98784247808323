// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-Spinner-Spinner-module_Spinner_aVS7j0 {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.src-components-Spinner-Spinner-module_Spinner_aVS7j0 > div {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
}

.src-components-Spinner-Spinner-module_Spinner_aVS7j0 > div > svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;AAChB","sourcesContent":[".Spinner {\n    width: 100%;\n    height: 100%;\n    display: grid;\n    place-items: center;\n}\n\n.Spinner > div {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    max-width: 200px;\n    max-height: 200px;\n}\n\n.Spinner > div > svg {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Spinner": `src-components-Spinner-Spinner-module_Spinner_aVS7j0`
};
export default ___CSS_LOADER_EXPORT___;
