// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon_kyXXIO {
    --animation: \\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon-anim-rotate_YWr774;
    display: inline-block;
    transition: all 0.2s;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon_kyXXIO.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_clickable_ghhTLy {
    cursor: pointer;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon_kyXXIO.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_clickable_ghhTLy:active {
    filter: brightness(150%);
    transform: scale(0.9);
}

.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon_kyXXIO.\\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_animate_xMVltt {
    animation: var(--animation) 0.6s linear infinite;
}

@keyframes \\---------------\\@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon-anim-rotate_YWr774 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/icons/generic/generic-icon.module.css"],"names":[],"mappings":"AAAA;IACI,0HAAoC;IACpC,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;AACzB;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".GenericIcon {\n    --animation: GenericIcon-anim-rotate;\n    display: inline-block;\n    transition: all 0.2s;\n}\n\n.GenericIcon.clickable {\n    cursor: pointer;\n}\n\n.GenericIcon.clickable:active {\n    filter: brightness(150%);\n    transform: scale(0.9);\n}\n\n.GenericIcon.animate {\n    animation: var(--animation) 0.6s linear infinite;\n}\n\n@keyframes GenericIcon-anim-rotate {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenericIcon": `---------------@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon_kyXXIO`,
	"GenericIcon-anim-rotate": `---------------@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_GenericIcon-anim-rotate_YWr774`,
	"clickable": `---------------@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_clickable_ghhTLy`,
	"animate": `---------------@tolokoban-ui-lib-dist-view-icons-generic-generic-icon-module_animate_xMVltt`
};
export default ___CSS_LOADER_EXPORT___;
