// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Panel-Panel-module_Panel_yNG0Yg {
    margin: 0;
    padding: 0;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Panel-Panel-module_Panel_yNG0Yg > *.flex-0 {
    flex: 0 0 auto;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Panel-Panel-module_Panel_yNG0Yg > *.flex-1 {
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Panel/Panel.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".Panel {\n    margin: 0;\n    padding: 0;\n}\n\n.Panel > :global(*.flex-0) {\n    flex: 0 0 auto;\n}\n\n.Panel > :global(*.flex-1) {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Panel": `---------------@tolokoban-ui-lib-dist-view-Panel-Panel-module_Panel_yNG0Yg`
};
export default ___CSS_LOADER_EXPORT___;
