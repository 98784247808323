// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-TraceButton-TraceButton-module_tracebutton_qaOfNX em {
    font-size: 80%;
    opacity: 0.5;
}

.\\------lib-tp-core-dist-ui-view-TraceButton-TraceButton-module_main_loEJ1R {
    padding: 0.5em;
}

.\\------lib-tp-core-dist-ui-view-TraceButton-TraceButton-module_main_loEJ1R:hover {
    background-color: var(--theme-color-secondary-5);
    color: var(--theme-color-on-secondary-5);
    box-shadow: 0 0.125em 0.25em #000;
    border-radius: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/TraceButton/TraceButton.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gDAAgD;IAChD,wCAAwC;IACxC,iCAAiC;IACjC,oBAAoB;AACxB","sourcesContent":[".tracebutton em {\n    font-size: 80%;\n    opacity: 0.5;\n}\n\n.main {\n    padding: 0.5em;\n}\n\n.main:hover {\n    background-color: var(--theme-color-secondary-5);\n    color: var(--theme-color-on-secondary-5);\n    box-shadow: 0 0.125em 0.25em #000;\n    border-radius: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tracebutton": `------lib-tp-core-dist-ui-view-TraceButton-TraceButton-module_tracebutton_qaOfNX`,
	"main": `------lib-tp-core-dist-ui-view-TraceButton-TraceButton-module_main_loEJ1R`
};
export default ___CSS_LOADER_EXPORT___;
