// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-ButtonView {
    width: 300px;
    height: 300px;
    border: none;
    box-shadow: 0 .125rem .25rem #0007;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
    margin: .5rem;
}

.map-ButtonView > * {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.map-ButtonView > header {
    bottom: auto;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 .5rem;
    color: #fffe;
    background-color: #000a;
    font-variant: small-caps;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/map/button/button-view.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kCAAkC;IAClC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;IACvB,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".map-ButtonView {\n    width: 300px;\n    height: 300px;\n    border: none;\n    box-shadow: 0 .125rem .25rem #0007;\n    cursor: pointer;\n    position: relative;\n    overflow: hidden;\n    border-radius: .5rem;\n    margin: .5rem;\n}\n\n.map-ButtonView > * {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n}\n\n.map-ButtonView > header {\n    bottom: auto;\n    height: 2rem;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 0 .5rem;\n    color: #fffe;\n    background-color: #000a;\n    font-variant: small-caps;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
