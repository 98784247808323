// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-ComboLang-ComboLang-module_TraceLangSelector_TK2K3W {
    display: grid;
    grid-template-columns: auto minmax(5em, 1fr) auto;
    gap: 0 0.5em;
    place-items: center;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/ComboLang/ComboLang.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iDAAiD;IACjD,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".TraceLangSelector {\n    display: grid;\n    grid-template-columns: auto minmax(5em, 1fr) auto;\n    gap: 0 0.5em;\n    place-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TraceLangSelector": `------lib-tp-core-dist-ui-view-ComboLang-ComboLang-module_TraceLangSelector_TK2K3W`
};
export default ___CSS_LOADER_EXPORT___;
