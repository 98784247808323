// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.guide-map-PopupManager {
    overflow: visible;
}

div.guide-map-PopupManager > div > div.leaflet-popup-content {
    margin: 0.25rem;
}

div.guide-map-PopupManager a.leaflet-popup-close-button {
    transform: translate(.8rem, -.8rem);
    background-color: var(--theme-color-accent);
    color: var(--theme-color-on-accent);
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.5rem #0008;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    padding: 0;
    display: inline-block;
    text-align: center;
}

div.guide-map-PopupManager.opacity-50 > div.leaflet-popup-content-wrapper,
div.guide-map-PopupManager.opacity-50 div.leaflet-popup-tip {
    background: #fff7;
}

div.guide-map-PopupManager.opacity-75 > div.leaflet-popup-content-wrapper,
div.guide-map-PopupManager.opacity-75 div.leaflet-popup-tip {
    background: #fffb;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/map/popup-manager.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,2CAA2C;IAC3C,mCAAmC;IACnC,kBAAkB;IAClB,kCAAkC;IAClC,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":["div.guide-map-PopupManager {\n    overflow: visible;\n}\n\ndiv.guide-map-PopupManager > div > div.leaflet-popup-content {\n    margin: 0.25rem;\n}\n\ndiv.guide-map-PopupManager a.leaflet-popup-close-button {\n    transform: translate(.8rem, -.8rem);\n    background-color: var(--theme-color-accent);\n    color: var(--theme-color-on-accent);\n    border-radius: 50%;\n    box-shadow: 0 0.25rem 0.5rem #0008;\n    height: 1.5rem;\n    width: 1.5rem;\n    line-height: 1.5rem;\n    padding: 0;\n    display: inline-block;\n    text-align: center;\n}\n\ndiv.guide-map-PopupManager.opacity-50 > div.leaflet-popup-content-wrapper,\ndiv.guide-map-PopupManager.opacity-50 div.leaflet-popup-tip {\n    background: #fff7;\n}\n\ndiv.guide-map-PopupManager.opacity-75 > div.leaflet-popup-content-wrapper,\ndiv.guide-map-PopupManager.opacity-75 div.leaflet-popup-tip {\n    background: #fffb;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
