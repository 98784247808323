import { ModalProvider } from "@tolokoban/ui"
import React from "react"

export default function LayoutRoot({
    children,
}: {
    children: React.ReactNode
}) {
    return <ModalProvider>{children}</ModalProvider>
}
