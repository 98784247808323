import { ServiceLogin } from "tp-core"
import { goto } from "@/app/routes"
import { RouteMatch, RoutePath } from "../types"

/**
 * Check if we have a loggin, an existing trace,
 * and if the user owns this trace.
 */
export default async function (
    path: RoutePath
): Promise<RoutePath | undefined> {
    const isLogged = await ServiceLogin.autologin()
    if (!isLogged) {
        console.warn("User needs to login!")
        return "/"
    }
    return
}
