import * as React from "react"
import { createRoot } from "react-dom/client"
import { Theme } from "@tolokoban/ui"
import { ServiceLogin } from "tp-core"

import App from "./app"

import "./index.css"

async function start() {
    try {
        new Theme({
            colors: {
                error: "#F87",
                input: "#ddccbc",
                neutral: ["#281403", "#55443b", "#a78d70"],
                primary: ["#1d6200", "#5bcc29", "#caf6b8"],
                secondary: ["#853e00", "#e7a128", "#fef5b6"],
            },
        }).apply()
        const root = createRoot(get("root"))
        root.render(<App />)
        await ServiceLogin.autologin()
    } finally {
        removeSplash()
    }
}

function removeSplash() {
    const splash = get("splash")
    splash.classList.add("vanish")
    window.setTimeout(() => {
        splash.parentNode?.removeChild(splash)
    }, 500)
}

function get(id: string): HTMLElement {
    const element = document.getElementById(id)
    if (!element) throw Error(`Unable to find element #${id}!`)

    return element
}

void start()
