// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-Spinner-Spinner-module_spinner_ouhmCn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.\\------lib-tp-core-dist-ui-view-Spinner-Spinner-module_spinner_ouhmCn > * {
    flex: 0 0 auto;
}

.\\------lib-tp-core-dist-ui-view-Spinner-Spinner-module_spinner_ouhmCn > div {
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".spinner {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 1em;\n}\n\n.spinner > * {\n    flex: 0 0 auto;\n}\n\n.spinner > div {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `------lib-tp-core-dist-ui-view-Spinner-Spinner-module_spinner_ouhmCn`
};
export default ___CSS_LOADER_EXPORT___;
