// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3 {
    font: inherit;
    background: transparent;
    width: auto;
    border: 1px solid color-mix(in srgb, currentColor, transparent 90%);
    background-color: color-mix(
        in srgb,
        var(--theme-color-input),
        transparent 40%
    );
    padding: 0 0.5em;
    line-height: 2em;
    height: 2em;
    color: inherit;
    border-bottom: 2px solid currentColor;
    transition: all 0.2s;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3:focus {
    background-color: var(--theme-color-input);
    color: var(--theme-color-on-input);
    border: none;
    outline: none;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_invalid_TjhuFm {
    background-color: var(--theme-color-error);
    color: var(--theme-color-on-error);
}

input.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3[type="number"]::-webkit-inner-spin-button,
input.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.\\---------------\\@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3[type="number"] {
    -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/InputText/InputText.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,mEAAmE;IACnE;;;;KAIC;IACD,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,qCAAqC;IACrC,oBAAoB;AACxB;;AAEA;IACI,0CAA0C;IAC1C,kCAAkC;IAClC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,0CAA0C;IAC1C,kCAAkC;AACtC;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".InputText {\n    font: inherit;\n    background: transparent;\n    width: auto;\n    border: 1px solid color-mix(in srgb, currentColor, transparent 90%);\n    background-color: color-mix(\n        in srgb,\n        var(--theme-color-input),\n        transparent 40%\n    );\n    padding: 0 0.5em;\n    line-height: 2em;\n    height: 2em;\n    color: inherit;\n    border-bottom: 2px solid currentColor;\n    transition: all 0.2s;\n}\n\n.InputText:focus {\n    background-color: var(--theme-color-input);\n    color: var(--theme-color-on-input);\n    border: none;\n    outline: none;\n}\n\n.InputText.invalid {\n    background-color: var(--theme-color-error);\n    color: var(--theme-color-on-error);\n}\n\ninput.InputText[type=\"number\"]::-webkit-inner-spin-button,\ninput.InputText[type=\"number\"]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\ninput.InputText[type=\"number\"] {\n    -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputText": `---------------@tolokoban-ui-lib-dist-view-InputText-InputText-module_InputText_sPAOw3`,
	"invalid": `---------------@tolokoban-ui-lib-dist-view-InputText-InputText-module_invalid_TjhuFm`
};
export default ___CSS_LOADER_EXPORT___;
