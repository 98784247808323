// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.\\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_error_cjhEM8 {
    margin: 0;
    padding: 1rem;
    background-color: var(--theme-color-error);
    color: var(--theme-color-on-error);
    max-width: 320px;
    overflow-x: auto;
}

div.\\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_error_cjhEM8 pre {
    white-space: pre-wrap;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_wait_WiWawc {
    padding: 1rem;
    background-color: var(--theme-color-neutral-5-9);
    color: var(--theme-color-on-neutral-5);
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    gap: 1rem;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_wait_WiWawc > * {
    flex: 1 1 auto;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_wait_WiWawc > svg {
    flex: 0 0 3rem;
    animation: \\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_rotation-anim_g9pCvC 1s linear infinite;
    width: 3rem;
    height: 3rem;
}

@keyframes \\---------------\\@tolokoban-ui-lib-dist-modal-manager-module_rotation-anim_g9pCvC {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/modal/manager.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,0CAA0C;IAC1C,kCAAkC;IAClC,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,gDAAgD;IAChD,sCAAsC;IACtC,oBAAoB;IACpB,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,+GAA2C;IAC3C,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["div.error {\n    margin: 0;\n    padding: 1rem;\n    background-color: var(--theme-color-error);\n    color: var(--theme-color-on-error);\n    max-width: 320px;\n    overflow-x: auto;\n}\n\ndiv.error pre {\n    white-space: pre-wrap;\n}\n\n.wait {\n    padding: 1rem;\n    background-color: var(--theme-color-neutral-5-9);\n    color: var(--theme-color-on-neutral-5);\n    display: inline-flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 480px;\n    gap: 1rem;\n}\n\n.wait > * {\n    flex: 1 1 auto;\n}\n\n.wait > svg {\n    flex: 0 0 3rem;\n    animation: rotation-anim 1s linear infinite;\n    width: 3rem;\n    height: 3rem;\n}\n\n@keyframes rotation-anim {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(-360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `---------------@tolokoban-ui-lib-dist-modal-manager-module_error_cjhEM8`,
	"wait": `---------------@tolokoban-ui-lib-dist-modal-manager-module_wait_WiWawc`,
	"rotation-anim": `---------------@tolokoban-ui-lib-dist-modal-manager-module_rotation-anim_g9pCvC`
};
export default ___CSS_LOADER_EXPORT___;
