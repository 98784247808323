// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-Runnable-Runnable-module_Runnable_b4uSp8 {
    position: relative;
    display: contents;
}

.\\------lib-tp-core-dist-ui-view-Runnable-Runnable-module_overlay_vZMNFe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(50%) blur(2px);
    z-index: 999999;
}

.\\------lib-tp-core-dist-ui-view-Runnable-Runnable-module_show_aDtpvi {
    transition: all 0.3s;
    opacity: 1;
    pointer-events: all;
}

.\\------lib-tp-core-dist-ui-view-Runnable-Runnable-module_hide_N1dqYV {
    transition: all 0.3s;
    opacity: 0;
    pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/Runnable/Runnable.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IAEI,oBAAoB;IACpB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IAEI,oBAAoB;IACpB,UAAU;IACV,oBAAoB;AACxB","sourcesContent":[".Runnable {\n    position: relative;\n    display: contents;\n}\n\n.overlay {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    backdrop-filter: brightness(50%) blur(2px);\n    z-index: 999999;\n}\n\n.show {\n    composes: overlay;\n    transition: all 0.3s;\n    opacity: 1;\n    pointer-events: all;\n}\n\n.hide {\n    composes: overlay;\n    transition: all 0.3s;\n    opacity: 0;\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Runnable": `------lib-tp-core-dist-ui-view-Runnable-Runnable-module_Runnable_b4uSp8`,
	"overlay": `------lib-tp-core-dist-ui-view-Runnable-Runnable-module_overlay_vZMNFe`,
	"show": `------lib-tp-core-dist-ui-view-Runnable-Runnable-module_show_aDtpvi ------lib-tp-core-dist-ui-view-Runnable-Runnable-module_overlay_vZMNFe`,
	"hide": `------lib-tp-core-dist-ui-view-Runnable-Runnable-module_hide_N1dqYV ------lib-tp-core-dist-ui-view-Runnable-Runnable-module_overlay_vZMNFe`
};
export default ___CSS_LOADER_EXPORT___;
