// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-DistanceView {
    padding: 0 0.25em;
    border-radius: 0.25em;
    white-space: nowrap;
}

.view-DistanceView > small {
    font-size: 80%;
    opacity: 0.5;
}

.view-DistanceView > span {
    font-size: 80%;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/Distance/distance-view.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".view-DistanceView {\n    padding: 0 0.25em;\n    border-radius: 0.25em;\n    white-space: nowrap;\n}\n\n.view-DistanceView > small {\n    font-size: 80%;\n    opacity: 0.5;\n}\n\n.view-DistanceView > span {\n    font-size: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
