// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Label-Label-module_LabelContent__6GF0I {
    all: inherit;
    margin: 0;
    padding: 0;
    display: contents;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Label-Label-module_Label_KIG3wx {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 90%;
    opacity: 0.667;
    margin-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Label/Label.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".LabelContent {\n    all: inherit;\n    margin: 0;\n    padding: 0;\n    display: contents;\n}\n\n.Label {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    font-size: 90%;\n    opacity: 0.667;\n    margin-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelContent": `---------------@tolokoban-ui-lib-dist-view-Label-Label-module_LabelContent__6GF0I`,
	"Label": `---------------@tolokoban-ui-lib-dist-view-Label-Label-module_Label_KIG3wx`
};
export default ___CSS_LOADER_EXPORT___;
