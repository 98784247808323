// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Touchable-Touchable-module_Touchable_sUqoQi {
    display: contents;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Touchable/Touchable.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".Touchable {\n    display: contents;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Touchable": `---------------@tolokoban-ui-lib-dist-view-Touchable-Touchable-module_Touchable_sUqoQi`
};
export default ___CSS_LOADER_EXPORT___;
