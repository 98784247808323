// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-TraceSearch-Input-Input-module_filter_uiYl3E {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/TraceSearch/Input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,6BAA6B;IAC7B,UAAU;AACd","sourcesContent":[".filter {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto auto;\n    gap: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `------lib-tp-core-dist-ui-view-TraceSearch-Input-Input-module_filter_uiYl3E`
};
export default ___CSS_LOADER_EXPORT___;
