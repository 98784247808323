// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.guide-map-OverlayManager-tooltip {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    overflow: visible;
    pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/map/overlay-manager.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,6BAA6B;IAC7B,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":["div.guide-map-OverlayManager-tooltip {\n    padding: 0;\n    margin: 0;\n    border: none;\n    border-radius: 0;\n    box-shadow: none;\n    background-color: transparent;\n    overflow: visible;\n    pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
