// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwidth_P1QN4S {
    inline-size: 100%;
    box-sizing: border-box;
}

.\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullheight_zGPukU {
    block-size: 100%;
    box-sizing: border-box;
}

.\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullsize_CmbYvg {
}

@media (orientation: landscape) {
    .\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwide_u00klP {
    }
    .\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullnarrow_xr2B4H {
    }
}

@media (orientation: portrait) {
    .\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwide_u00klP {
    }
    .\\---------------\\@tolokoban-ui-lib-dist-theme-css-dimension-module_fullnarrow_xr2B4H {
    }
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/theme/css/dimension.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;AAEA;;AAEA;IACI;IAEA;IACA;IAEA;AACJ;;AAEA;IACI;IAEA;IACA;IAEA;AACJ","sourcesContent":[".fullwidth {\n    inline-size: 100%;\n    box-sizing: border-box;\n}\n\n.fullheight {\n    block-size: 100%;\n    box-sizing: border-box;\n}\n\n.fullsize {\n    composes: fullwidth fullheight;\n}\n\n@media (orientation: landscape) {\n    .fullwide {\n        composes: fullwidth;\n    }\n    .fullnarrow {\n        composes: fullheight;\n    }\n}\n\n@media (orientation: portrait) {\n    .fullwide {\n        composes: fullheight;\n    }\n    .fullnarrow {\n        composes: fullwidth;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullwidth": `---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwidth_P1QN4S`,
	"fullheight": `---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullheight_zGPukU`,
	"fullsize": `---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullsize_CmbYvg ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwidth_P1QN4S ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullheight_zGPukU`,
	"fullwide": `---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwide_u00klP ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwidth_P1QN4S ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullheight_zGPukU`,
	"fullnarrow": `---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullnarrow_xr2B4H ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullheight_zGPukU ---------------@tolokoban-ui-lib-dist-theme-css-dimension-module_fullwidth_P1QN4S`
};
export default ___CSS_LOADER_EXPORT___;
