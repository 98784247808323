import React from "react"
import { ViewPanel } from "@tolokoban/ui"
import Spinner from "@/components/Spinner"

export default function Loading() {
    return (
        <ViewPanel
            display="grid"
            width="100%"
            height="100%"
            color="neutral-1"
            placeItems="center"
        >
            <Spinner />
        </ViewPanel>
    )
}
