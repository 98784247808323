// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
    font-size: min(16px, 3vw);
    font-family: josefin-sans, sans-serif;
}

* {
    box-sizing: border-box;
}

div#root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: var(--thm-color-0);
}

label.tfw-view-Label {
    opacity: 0.5;
}

p {
    font-family: 80%;
    background-color: var(--theme-color-screen);
    color: var(--theme-color-on-screen-opacity-50);
    padding: 0.5em 1em;
    margin: 0.5em;
    border-radius: 0 0.5em 0.5em 0;
    border-left: 0.25em solid var(--theme-color-accent-opacity-50);
}

#splash {
    transition: 0.5s all;
    opacity: 1;
}

#splash.vanish {
    pointer-events: none;
    opacity: 0;
}

hr {
    height: 1px;
    background: linear-gradient(
        to left,
        transparent,
        currentColor,
        currentColor,
        transparent
    );
    border: none;
    padding: 0;
    margin: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,oCAAoC;AACxC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,2CAA2C;IAC3C,8CAA8C;IAC9C,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,8DAA8D;AAClE;;AAEA;IACI,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,WAAW;IACX;;;;;;KAMC;IACD,YAAY;IACZ,UAAU;IACV,WAAW;AACf","sourcesContent":["html,\r\nbody {\r\n    font-size: min(16px, 3vw);\r\n    font-family: josefin-sans, sans-serif;\r\n}\r\n\r\n* {\r\n    box-sizing: border-box;\r\n}\r\n\r\ndiv#root {\r\n    position: absolute;\r\n    left: 0;\r\n    top: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    padding: 0;\r\n    margin: 0;\r\n    overflow: hidden;\r\n    background-color: var(--thm-color-0);\r\n}\r\n\r\nlabel.tfw-view-Label {\r\n    opacity: 0.5;\r\n}\r\n\r\np {\r\n    font-family: 80%;\r\n    background-color: var(--theme-color-screen);\r\n    color: var(--theme-color-on-screen-opacity-50);\r\n    padding: 0.5em 1em;\r\n    margin: 0.5em;\r\n    border-radius: 0 0.5em 0.5em 0;\r\n    border-left: 0.25em solid var(--theme-color-accent-opacity-50);\r\n}\r\n\r\n#splash {\r\n    transition: 0.5s all;\r\n    opacity: 1;\r\n}\r\n\r\n#splash.vanish {\r\n    pointer-events: none;\r\n    opacity: 0;\r\n}\r\n\r\nhr {\r\n    height: 1px;\r\n    background: linear-gradient(\r\n        to left,\r\n        transparent,\r\n        currentColor,\r\n        currentColor,\r\n        transparent\r\n    );\r\n    border: none;\r\n    padding: 0;\r\n    margin: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
