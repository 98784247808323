// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-modal-container-module_container_zua9X2 {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    overflow: auto;
    pointer-events: all;
    z-index: 999999;
    backdrop-filter: blur(1px);
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-container-module_L_VvbSzu {
    justify-items: start;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-container-module_R_Mm5ckd {
    justify-items: end;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-container-module_T_sNNP77 {
    align-items: start;
}

.\\---------------\\@tolokoban-ui-lib-dist-modal-container-module_B_pDEh1B {
    align-items: end;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/modal/container.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    display: grid;\n    justify-items: center;\n    align-items: center;\n    overflow: auto;\n    pointer-events: all;\n    z-index: 999999;\n    backdrop-filter: blur(1px);\n}\n\n.L {\n    justify-items: start;\n}\n\n.R {\n    justify-items: end;\n}\n\n.T {\n    align-items: start;\n}\n\n.B {\n    align-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `---------------@tolokoban-ui-lib-dist-modal-container-module_container_zua9X2`,
	"L": `---------------@tolokoban-ui-lib-dist-modal-container-module_L_VvbSzu`,
	"R": `---------------@tolokoban-ui-lib-dist-modal-container-module_R_Mm5ckd`,
	"T": `---------------@tolokoban-ui-lib-dist-modal-container-module_T_sNNP77`,
	"B": `---------------@tolokoban-ui-lib-dist-modal-container-module_B_pDEh1B`
};
export default ___CSS_LOADER_EXPORT___;
