// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\------lib-tp-core-dist-ui-view-InputTime-InputTime-module_main_DmAoui {
    display: flex inline;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
}

.\\------lib-tp-core-dist-ui-view-InputTime-InputTime-module_main_DmAoui div.\\------lib-tp-core-dist-ui-view-InputTime-InputTime-module_row_CHeIh_ {
    display: flex inline;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.\\------lib-tp-core-dist-ui-view-InputTime-InputTime-module_main_DmAoui div.\\------lib-tp-core-dist-ui-view-InputTime-InputTime-module_row_CHeIh_ > div {
    flex: 1 0 auto;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./../../lib/tp-core/dist/ui/view/InputTime/InputTime.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,2BAA2B;IAC3B,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,SAAS;AACb","sourcesContent":[".main {\n    display: flex inline;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: stretch;\n    flex-wrap: nowrap;\n}\n\n.main div.row {\n    display: flex inline;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: nowrap;\n}\n\n.main div.row > div {\n    flex: 1 0 auto;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `------lib-tp-core-dist-ui-view-InputTime-InputTime-module_main_DmAoui`,
	"row": `------lib-tp-core-dist-ui-view-InputTime-InputTime-module_row_CHeIh_`
};
export default ___CSS_LOADER_EXPORT___;
