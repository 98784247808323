// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_Dialog_puFExM {
    display: inline-block;
    --custom-color-header-text: var(--theme-color-on-neutral-4);
    --custom-color-header-back: var(--theme-color-neutral-4);
    --custom-color-main-text: var(--theme-color-on-neutral-4);
    --custom-color-main-back: var(--theme-color-neutral-4);
    --custom-color-footer-text: var(--theme-color-on-neutral-6);
    --custom-color-footer-back: var(--theme-color-neutral-6);
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_Dialog_puFExM > header.\\---------------\\@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_header_x24SqK {
    padding: var(--theme-gap-S) var(--theme-gap-M);
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_Dialog_puFExM > footer {
    display: flex;
    justify-content: space-around;
    gap: 1em;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Dialog/Dialog.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2DAA2D;IAC3D,wDAAwD;IACxD,yDAAyD;IACzD,sDAAsD;IACtD,2DAA2D;IAC3D,wDAAwD;AAC5D;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,QAAQ;AACZ","sourcesContent":[".Dialog {\n    display: inline-block;\n    --custom-color-header-text: var(--theme-color-on-neutral-4);\n    --custom-color-header-back: var(--theme-color-neutral-4);\n    --custom-color-main-text: var(--theme-color-on-neutral-4);\n    --custom-color-main-back: var(--theme-color-neutral-4);\n    --custom-color-footer-text: var(--theme-color-on-neutral-6);\n    --custom-color-footer-back: var(--theme-color-neutral-6);\n}\n\n.Dialog > header.header {\n    padding: var(--theme-gap-S) var(--theme-gap-M);\n}\n\n.Dialog > footer {\n    display: flex;\n    justify-content: space-around;\n    gap: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dialog": `---------------@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_Dialog_puFExM`,
	"header": `---------------@tolokoban-ui-lib-dist-view-Dialog-Dialog-module_header_x24SqK`
};
export default ___CSS_LOADER_EXPORT___;
