// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerHorizontal_Va_XZ6 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerHorizontal_Va_XZ6 > svg {
    width: 1em;
    height: 1em;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerVertical_ZVQfco {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1em;
}

.\\---------------\\@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerVertical_ZVQfco > svg {
    width: 5em;
    height: 5em;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".SpinnerHorizontal {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 1em;\n}\n\n.SpinnerHorizontal > svg {\n    width: 1em;\n    height: 1em;\n}\n\n.SpinnerVertical {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    gap: 1em;\n}\n\n.SpinnerVertical > svg {\n    width: 5em;\n    height: 5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpinnerHorizontal": `---------------@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerHorizontal_Va_XZ6`,
	"SpinnerVertical": `---------------@tolokoban-ui-lib-dist-view-Spinner-Spinner-module_SpinnerVertical_ZVQfco`
};
export default ___CSS_LOADER_EXPORT___;
