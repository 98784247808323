// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../trail-passion/tp-website/v5/pages/app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------------\\@tolokoban-ui-lib-dist-view-Strip-Strip-module_Strip_ckBUSd {
    display: grid;
    place-items: stretch;
    padding: 0;
    margin: 0;
    gap: 0;
}
`, "",{"version":3,"sources":["webpack://./../../../../../@tolokoban/ui/lib/dist/view/Strip/Strip.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,UAAU;IACV,SAAS;IACT,MAAM;AACV","sourcesContent":[".Strip {\n    display: grid;\n    place-items: stretch;\n    padding: 0;\n    margin: 0;\n    gap: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Strip": `---------------@tolokoban-ui-lib-dist-view-Strip-Strip-module_Strip_ckBUSd`
};
export default ___CSS_LOADER_EXPORT___;
